<template>
  <div class="shop">
    <div
      v-if="featuredArtwork"
      class="shop__background"
      :style="{
        backgroundImage: `url(${featuredArtwork.header_image})`,
      }"
    ></div>

    <div class="shop__content">
      <div v-if="featuredArtwork" class="shop__details-box">
        <span class="shop__details-box_subtitle">FEATURED PIECE</span>
        <h2 class="shop__details-box_title">{{ featuredArtwork.title }}</h2>
        <span v-if="featuredArtwork.artist" class="shop__details-box_artist">
          {{ featuredArtwork.artist.first_name }} {{ featuredArtwork.artist.last_name }}
        </span>
        <div
          class="shop__details-box_details collect-editor collect-editor--white"
          v-html="featuredArtwork.description"
        ></div>
        <BaseButton
          class="shop__details-box_button button--xs button--white"
          text="VIEW"
          @click="toArtworks(`/artworks/${featuredArtwork.slug}`)"
        />
      </div>

      <div class="shop__title-box">
        <h2 class="shop__title-box_title">Artworks</h2>
        <div class="shop__title-box_right">
          <div class="shop__title-box_right-content">
            <PublicSelect
              :items="sortData"
              :selected="cardData.sort"
              @input="selectSort"
            ></PublicSelect>
            <FilterBlock
              style="margin-left: 15px"
              :items="filters"
              :search="search"
              @open="openFilters = $event"
              @selectFilter="selectFilter"
              @apply="refreshSelectedFilters"
              @clear="refreshSelectedFilters(true)"
              @changeSearch="changeSearch"
            ></FilterBlock>
          </div>
        </div>
      </div>

      <div v-if="shopList.length" class="shop__artworks-box">
        <a
          v-for="(item, key) in shopList"
          :key="`artwork-${key}`"
          :href="`/artworks/${item.slug}`"
          class="shop__artwork-link"
          @click.prevent="toArtworks(`/artworks/${item.slug}`)"
        >
          <div class="shop__artwork-card">
            <div class="image-box">
              <img class="image" :src="item.search_image" />
              <!-- <div v-if="item.sku" class="code">{{ item.sku }}</div> -->
              <div class="hover-container">Click to View</div>
            </div>
            <h3 class="title">{{ item.title }}</h3>
            <!-- <span class="price">${{ item.price }}</span> -->
            <span class="author">{{ item.artist.first_name }} {{ item.artist.last_name }}</span>
          </div>
        </a>
      </div>
      <h6 class="shop__empty" v-else>No artworks</h6>

      <div v-if="hasMorePages" class="shop__button-box">
        <BaseButton
          class="shop__button-box_button button--xs button--white"
          text="LOAD MORE"
          @click.native="loadMore"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import BaseButton from "@/components/buttons/BaseButton.vue";
import PublicSelect from "@/components/inputs/PublicSelect.vue";
import FilterBlock from "@/components/FilterPublic.vue";

export default {
  components: { BaseButton, PublicSelect, FilterBlock },

  data() {
    return {
      sortData: [
        { id: "newest_to_oldest", title: "Newest to Oldest" },
        { id: "oldest_to_newest", title: "Oldest to Newest" },
        { id: "artist_a_z", title: "Artist (A-Z)" },
        { id: "artist_z_a", title: "Artist (Z-A)" },
        { id: "title_a_z", title: "Art (A-Z)" },
        { id: "title_z_a", title: "Art (Z-A)" },
      ],
      cardData: {
        page: 1,
        sort: "newest_to_oldest",
        art_types: "",
        materials: "",
        price_start: "",
        price_end: "",
        periods: "",
        colors: "",
        regions: "",
        availabilities: "",
        width_start: "",
        width_end: "",
        height_start: "",
        height_end: "",
        depth_start: "",
        depth_end: "",
      },
      filterBlock: {
        list: "art_types,materials,price,periods,colors,regions,availabilities,sizes",
        type: "collection",
      },
      openFilters: false,
    };
  },
  head: {
    title: function () {
      return {
        inner: "Artworks browngrotta arts",
      };
    },
    meta: function () {
      return [
        {
          name: "description",
          itemprop: "description",
          content: "Artworks browngrotta arts",
          id: "meta:description",
        },
        {
          itemprop: "name",
          content: "Artworks browngrotta arts",
          id: "meta:name",
        },
        {
          itemprop: "image",
          content:
            this.featuredArtwork.header_image ??
            `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "meta:image",
        },
        {
          property: "og:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "og:url",
        },
        {
          property: "og:type",
          content: "website",
          id: "og:type",
        },
        {
          property: "og:title",
          content: "Artworks browngrotta arts",
          id: "og:title",
        },
        {
          property: "og:image",
          content:
            this.featuredArtwork.header_image ??
            `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "og:image",
        },
        {
          property: "og:image:alt",
          content: "Artworks browngrotta arts",
          id: "og:image:alt",
        },
        {
          property: "og:description",
          content: "Description Artworks browngrotta arts",
          id: "og:description",
        },
        {
          property: "og:site_name",
          content: process.env.VUE_APP_FRONTEND_URL,
          id: "og:site_name",
        },
        {
          property: "og:locale",
          content: "en_US",
          id: "og:locale",
        },
        {
          name: "twitter:card",
          content: "summary",
          id: "twitter:card",
        },
        {
          name: "twitter:site",
          content: "@BrowngrottaArts",
          id: "twitter:site",
        },
        {
          name: "twitter:creator",
          content: "@BrowngrottaArts",
          id: "twitter:creator",
        },
        {
          name: "twitter:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "twitter:url",
        },
        {
          name: "twitter:title",
          content: "Artworks browngrotta arts",
          id: "twitter:title",
        },
        {
          name: "twitter:description",
          content: "Description Artworks browngrotta arts",
          id: "twitter:description",
        },
        {
          name: "twitter:image",
          content:
            this.featuredArtwork.header_image ??
            `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "twitter:image",
        },
        {
          name: "twitter:image:alt",
          content: "Artworks browngrotta arts",
          id: "twitter:image:alt",
        },
      ];
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.path.startsWith(to.path)) {
        if (vm.statePage.page) {
          vm.cardData.page = vm.statePage.page;
          //window.scrollTo({ top: vm.statePage.scroll });
        }
      } else {
        vm.setStatePage({ page: null, scroll: null });
      }
    });
  },
  async created() {
    if (this.statePage.page === null) {
      await this.apiGetShopList(this.cardData);
      this.$emit("updateHead");
      await this.apiGetFiltersBlock({ ...this.filterBlock, search: this.search, isFirst: true });
    }

    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Artworks",
        to: "/artworks",
        clickable: false,
      },
    ];
    this.setBreadcrumbs(breadcrumbs);
  },

  computed: {
    ...mapGetters("publicapi/shop", {
      shopList: "getShopList",
      featuredArtwork: "getFeaturedArtwork",
      hasMorePages: "getHasMorePages",
      statePage: "getStatePage",
    }),
    ...mapGetters("publicapi/filters", {
      initialfilters: "getInitialfilters",
      filters: "getFilters",
      search: "getSearch",
      selectedFilters: "getSelectedFilters",
    }),
  },

  methods: {
    ...mapActions("publicapi/shop", ["apiGetShopList"]),
    ...mapActions("publicapi/filters", ["apiGetFiltersBlock", "clear"]),
    ...mapMutations("publicapi/filters", [
      "updateFilters",
      "updateSelectedFilters",
      "removeFromSelectedFilters",
    ]),
    ...mapMutations(["setBreadcrumbs"]),
    ...mapMutations("publicapi/shop", ["setStatePage"]),

    async loadMore() {
      if (this.hasMorePages) {
        let data = { ...this.cardData, page: this.cardData.page + 1 };
        let success = await this.apiGetShopList(data);
        if (success) {
          this.cardData = data;
        }
      }
    },
    async selectSort({ id }) {
      let data = { ...this.cardData, page: 1, sort: id };
      let success = await this.apiGetShopList(data);
      if (success) {
        this.cardData = { ...data };
      }
    },
    selectFilter(val) {
      if (val.apply) {
        this.removeFromSelectedFilters(val);
      }
      this.updateFilters(val);
      if (val.apply) {
        this.refreshSelectedFilters();
      }
    },
    async refreshSelectedFilters(clear) {
      if (clear) {
        await this.clear(this.initialfilters);
      }
      await this.updateSelectedFilters();
      this.filterTable();
    },
    async filterTable() {
      let data = { ...this.cardData, page: 1 };

      this.selectedFilters.forEach(el => {
        const values = ["price", "width", "height", "depth"];
        if (values.includes(el.key)) {
          data[`${el.key}_start`] = "";
          data[`${el.key}_end`] = "";
          el.items.forEach(i => {
            data[i.key] = i.val;
          });
        } else {
          let filters = el.items.map(i => {
            return i.id;
          });
          data[el.key] = filters.join(",");
        }
      });
      let success = await this.apiGetShopList(data);
      if (success) {
        this.cardData = data;
      }
    },
    toArtworks(path) {
      this.setStatePage({ page: this.cardData.page, scroll: window.scrollY });
      this.$router.push(path);
    },
    async changeSearch(search) {
      await this.apiGetFiltersBlock({ ...this.filterBlock, search });
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  color: $white;
  font-size: 18px;
  line-height: 1.33;
  @media screen and (max-width: $xs) {
    font-size: 16px;
    line-height: 1.56;
  }
}

.shop {
  display: flex;

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 58.4rem;
    z-index: 0;
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
    @media screen and (max-width: $xs) {
      height: 33.5rem;
    }
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba($color: $lightBlack, $alpha: 0.45);
      z-index: 1;
    }
    &::after {
      content: "";
      bottom: -1px;
      position: absolute;
      width: 100%;
      height: 50%;
      background: linear-gradient(
        0deg,
        rgba($color: $lightBlack, $alpha: 1) 0%,
        rgba($color: $lightBlack, $alpha: 0) 100%
      );
      z-index: 1;
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 40rem 10.5rem 24rem;
    // z-index: 2;
    @media screen and (max-width: $xs) {
      padding: 24.5rem 2rem 11rem;
    }
  }

  &__details-box {
    display: flex;
    flex-direction: column;
    width: 55%;
    @media screen and (max-width: $md) {
      width: 100%;
    }

    &_subtitle {
      font-size: 16px;
      line-height: 1.56;
      @media screen and (max-width: $xs) {
        font-size: 14px;
      }
    }

    &_title {
      font-size: 40px;
      line-height: 1.25;
      @media screen and (max-width: $xs) {
        font-size: 35px;
        // line-height: 1.23px;
        margin-bottom: 10px;
      }
    }

    &_artist {
      font-size: 24px;
      line-height: 2;
      @media screen and (max-width: $xs) {
        font-size: 20px;
        line-height: 1.25;
        margin-bottom: 17px;
      }
    }

    &_details {
    }

    &_button {
      margin-top: 24px;
      min-width: 180px;
      @media screen and (max-width: $xs) {
        margin-top: 35px;
      }
    }
  }

  &__title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 9rem 0rem 6rem;
    @media screen and (max-width: $lg) {
      flex-direction: column;
      align-items: flex-start;
    }
    @media screen and (max-width: $xs) {
      margin: 8.5rem 0rem 2rem;
    }
    &_right {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @media screen and (max-width: $md) {
        width: 100%;
        flex-direction: column;
        .divider {
          display: none;
        }
        &-content {
          &:last-of-type {
            margin-left: 10px;
          }
        }
      }
      &-content {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        & > * {
          margin-bottom: 10px;
        }
      }
      @media screen and (max-width: $lg) {
        margin-top: 30px;
        margin-left: -10px;
      }
      @media screen and (max-width: $xs) {
        &-content {
          flex-wrap: wrap;
        }
      }
    }

    &_title {
      font-size: 40px;
      line-height: 1.25;
      @media screen and (max-width: $xs) {
        font-size: 35px;
        line-height: 1.23rem;
      }
    }
  }
  &__empty {
    font-size: 20px;
    font-weight: 600;
    padding: 50px 10px;
    text-align: center;
    text-transform: uppercase;
  }

  &__artworks-box {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(30%, 1fr));
    grid-column-gap: 3rem;
    grid-row-gap: 7rem;
    @media screen and (max-width: $md) {
      grid-template-columns: repeat(2, minmax(30%, 1fr));
    }
    @media screen and (max-width: $xs) {
      grid-template-columns: minmax(30%, 1fr);
      grid-row-gap: 7rem;
    }
  }

  &__artwork {
    &-link {
      text-decoration: none;
    }
    &-card {
      display: flex;
      flex-direction: column;
      cursor: pointer;

      &:hover {
        .image-box {
          .hover-container {
            opacity: 1;
          }
        }
      }

      .image-box {
        position: relative;

        .image {
          height: 27vw;
          width: 100%;
          object-fit: cover;
          background-size: cover;
          background-position: center;
          border-radius: 4px;
          @media screen and (max-width: $xs) {
            height: 90vw;
          }
        }

        // .code {
        //   position: absolute;
        //   right: 9px;
        //   bottom: 9px;
        //   text-transform: uppercase;
        //   border: 1px solid $white;
        //   border-radius: 5px;
        //   background-color: rgba(0, 0, 0, 0.45);
        //   max-width: 30%;
        //   min-width: 7.2rem;
        //   font-size: 1.4rem;
        //   line-height: 2.5rem;
        //   padding: 0.1rem 1rem;
        //   z-index: 2;
        //   display: block;
        //   white-space: nowrap;
        //   overflow: hidden;
        //   text-overflow: ellipsis;
        // }

        .hover-container {
          position: absolute;
          width: 100%;
          height: 100%;
          font-size: 3rem;
          line-height: 5rem;
          background-color: rgba($color: $blue, $alpha: 0.83);
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.3s ease;
          opacity: 0;
          top: 0;
          border-radius: 4px;
        }
      }

      .title {
        font-size: 2.4rem;
        line-height: 3.1rem;
        margin-top: 1rem;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-clamp: 2;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      // .price {
      //   font-size: 1.8rem;
      //   line-height: 2.8rem;
      // }

      .author {
        margin-top: 0.5rem;
        font-size: 1.4rem;
        line-height: 2.5rem;
        text-transform: uppercase;
        word-spacing: 3px;
        @media screen and (max-width: $xs) {
          margin-top: 1rem;
          font-size: 1.6rem;
          line-height: 2.8rem;
        }
      }
    }
  }

  &__button-box {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
    margin-top: 7rem;

    &_button {
      min-width: 180px;
    }
  }
}
</style>

